<template>
  <iframe :id="id" ref="iframeObject" :src="url" width="100%" frameborder="0" style="visibility: hidden" />
</template>

<script setup lang="ts">
// Libs

// Models
import { useToast } from '../../store/useToast'

/** Global Const */
const toast = useToast()

export interface Props {
  id?: string
  url?: string
  loadFunc?: (() => void) | null
}

const props = withDefaults(defineProps<Props>(), {
  id: '',
  url: '',
  loadFunc: null
})

const iframeObject = ref()
//const iframeHeight = ref(400)
const iframeTimestamp = ref(0)

window.addEventListener('message', (e) => {
  if (e.data.functionName === 'resize') {
    //iframeHeight.value = iframeObject.value.contentDocument.body.offsetHeight // + 100
    resizeIframe()
  }

  const link = document.createElement('link')
  link.id = 'editorCss'
  link.rel = 'stylesheet'
  link.type = 'text/css'
  link.href = `${window.location.protocol}//${window.location.hostname}/PortalResources/css/ForPortal/editor/view/editor.view.ce.css`

  try {
    iframeObject.value.contentDocument.head.append(link)
  } catch {}
})

const resizeIframe = () => {
  const intervalObj = setInterval(function () {
    try {
      //iframeHeight.value = iframeObject.value.contentDocument.body.offsetHeight
      iframeObject.value.height = iframeObject.value.contentDocument.body.offsetHeight
      iframeObject.value.style.visibility = 'visible'
      //   toast.info(iframeObject.value.contentDocument.body.offsetHeight)
    } catch {}
    iframeTimestamp.value += 500
    if (iframeTimestamp.value >= 30000) {
      clearInterval(intervalObj)
    }
  }, 500)
}
</script>
