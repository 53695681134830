<template>
  <!-- 입력 -->
  <template v-if="renderType == 'edit'">
    <div class="row row-option">
      <div class="col">
        <div class="row" style="width: 100%">
          <div class="col col-header">
            <div class="value">
              {{ props.label ?? $t('ArticleTag') }}
            </div>
          </div>
          <div class="col col-data">
            <div class="value">
              <TagInput
                v-model="tagsForInput"
                :placeholder="props.placeHolder ?? $t('ArticleTagPlaceholder')"
                @update:model-value="handleChangeTag" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <!-- 조회 -->
  <template v-else>
    <div v-if="modelValue" class="sect_tag">
      <div class="page_title">
        <div class="left_area">
          <span class="title">{{ props.label ?? $t('ArticleTag') }}</span>
        </div>
      </div>
      <div class="tagcloud" style="font-size: 15px; margin-left: auto">
        <a v-for="tag of computeTags" :key="tag" class="tag" @click.prevent="handleTagClick(tag)">{{ tag }}</a>
      </div>
    </div>
    <tag-popup v-model="showBoardPopup" :tag="selectionTag" :system-type="systemType" :item-group-id="itemGroupId" />
  </template>
</template>

<script setup lang="ts">
import TagInput from './TagInput.vue'

import { TagApi, PushTagRequestModel } from 'mik-common/src/api-autogen/taggingcenter'

import TagPopup from 'mik-common/src/components/tag/TagPopup.vue'

const tagApi = new TagApi()

/**
 * modelValue : 태그 전체를 하나의 문자열(string) 으로 받는다.
 * renderType :
 *  입력 시 edit 로 입력하고 데이터는 동기화를 위하여 v-model:model-value 을 사용한다.
 *  조회 시 view 로 입력하고 출력만을 위한 :model-value 을 사용한다.
 * label : 다른 컬럼명으로 사용하고 싶을 경우 사용
 * placeHolder : 상세 입력 안내가 필요한 경우 사용
 */
interface Props {
  modelValue?: string | null
  renderType: 'edit' | 'view'
  label?: string
  placeHolder?: string
  systemType?: string
  itemGroupId?: string | null
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  label: undefined,
  placeHolder: undefined,
  systemType: '',
  itemGroupId: undefined
})

const showBoardPopup = ref(false)
const selectionTag = ref<string>()

/** 하나의 문자열을 배열처리 한다. */
const computeTags = computed(() => {
  if (!props.modelValue) {
    return []
  }
  return props.modelValue.split(',').map((o) => o.trim())
})

const tagsForInput = ref<string[]>(computeTags.value)

/** Tagging Center 에도 저장 시 호출 (외부에서 호출) */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const pushTag = (request: PushTagRequestModel) => {
  tagApi.v1TagPushPost(request).catch((error) => {
    console.log(error)
  })
}

/** 태그 선택 시 이벤트 */
const handleTagClick = (val: string) => {
  showBoardPopup.value = true
  selectionTag.value = val
}

/** 라이브러리의 문자 배열을 하나의 문자열로 반환한다. */
const handleChangeTag = (tags: string[]) => {
  console.log('handleChangeTag', tags)
  emit('update:modelValue', tags.join(','))
}

// 이벤트
const emit = defineEmits<{ (e: 'update:modelValue', tags: string): void }>()
// 함수
defineExpose({ pushTag })
</script>

<style scoped lang="scss">
.tagcloud {
  .tag {
    cursor: pointer;
  }
}
</style>
