<template>
  <div class="mik-user">
    <span v-for="(selected, index) of selectedItemArr" :key="index" class="taginput_tag">
      <div class="comb-box">
        <img class="photo" :src="`${profileUrl}${selected.userID}`" />
        <span class="name">{{ selected.displayName }}</span>
        <span class="delete_tag" @click.prevent="deleteSelectedItem(index)">
          <svg
            width="16"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:svgjs="http://svgjs.com/svgjs">
            <defs></defs>
            <g class="svg_delete_btn">
              <rect width="16" height="16" stroke="#000000" stroke-width="0.6" fill="#ffffff"></rect>
              <!-- eslint-disable-next-line vue/max-attributes-per-line -->
              <line x1="5" y1="5" x2="11" y2="11" stroke="#000000" stroke-width="1.2"></line>
              <!-- eslint-disable-next-line vue/max-attributes-per-line -->
              <line x1="11" y1="5" x2="5" y2="11" stroke="#000000" stroke-width="1.2"></line>
            </g>
          </svg>
        </span>
      </div>
    </span>
    <input
      v-show="isShowInput"
      ref="inputRef"
      class="mik-user-input"
      type="text"
      :placeholder="placeholder"
      :value="textValue"
      autocomplete="off"
      v-bind="$attrs"
      :disabled="props.isDisable"
      @input="handleKeyword"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @keydown.enter.prevent="selectCurrentSelection"
      @keydown.delete="deleteInput"
      @keydown.tab.prevent="selectCurrentSelectionTab" />

    <div v-if="isListVisible" ref="mikUserList" class="autocomplete-suggestions">
      <!-- @vue-ignore -->
      <div
        v-for="(item, index) in filteredItems"
        :key="index"
        ref="mikUserListItem"
        :class="getClass(index)"
        @mousedown.prevent
        @click.prevent="selectItem(item)"
        @mouseenter="handleMouseEnter(index)">
        <div class="photo-box">
          <img class="photo" :src="`/${profileUrl}${item.userID}`" />
        </div>
        <div class="description-box">
          <div class="name" v-html="boldMatchText(item.displayName)"></div>
          <div class="email">{{ item.emailAddress }}</div>
        </div>
      </div>
    </div>
    <mik-button
      :button-txt="$t(buttonText)"
      :button-type="'secondary'"
      class="orgchart-btn"
      :disable="props.isDisable"
      @click.prevent="clickOrgchart" />
  </div>
</template>

<script lang="ts" setup>
// @ts-nocheck
import axios from 'axios'
import OrgModel from '../../api-autogen/orgchart/models'
import { useToast } from '../../store/useToast'
import { om_OpenOrgChart, upperCaseKeys, lowerCaseKeys } from '../../utils/orgchart'
import { useI18n } from 'vue-i18n'
import MikButton from '../common/MikButton.vue'

export interface Props {
  modelValue?: OrgModel[] | null
  placeholder?: string
  selectedItem?: OrgModel[]
  maxLengthItem?: number
  selectOnTab?: boolean
  searchUrl?: string
  profileUrl?: string
  isDisable?: boolean
  buttonText?: string
  langCode?: 'ko' | 'en' | 'zh'
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  placeholder: '',
  selectedItem: () => [],
  maxLengthItem: 1,
  selectOnTab: true,
  searchUrl: `${import.meta.env.V_API_ORGCHART_PATH}/api/v1/user/Search`,
  profileUrl: `${import.meta.env.V_API_GLOBALMENU_PATH}/v1/resource/image/profile/`,
  isDisable: false,
  buttonText: 'OrgChart',
  langCode: 'ko'
})

const inputRef = ref()
const mikUserList = ref()
const mikUserListItem = ref()

const textValue = ref('')
const isInputFocused = ref(false)
const currentSelectionIndex = ref(0)
let emptyValue = false
const isShowInput = computed(() => {
  if (props.maxLengthItem == selectedItemArr.length) {
    return false
  }
  return true
})

const selectedItemArr: OrgModel[] = reactive([])
const filteredItems: OrgModel[] = reactive([])
// const selectedItemArr = computed({
//   get() {
//     return props.modelValue
//   },
//   set(value) {
//     emit('update:modelValue', value)
//   }
// })

const toast = useToast()
const { t } = useI18n()

let intervalId: number = 0

onBeforeMount(() => {
  if (props.modelValue) Object.assign(selectedItemArr, props.modelValue)
})

onMounted(() => {
  if (props.selectedItem != null) {
    props.selectedItem.forEach((item) => {
      selectItem(item)
    })
  }

  // OrgModel이 조직도에서는 파스칼case로 사용하고, 현재 v2에서는 카멜case로 사용되어 혼동이 있다.
  // 조직도: 파스칼case, 포탈v2 Client: 카멜case, 포탈v1 DB: 파스칼case
  if (props.modelValue != null) {
    Object.assign(
      selectedItemArr,
      props.modelValue.map((item) => lowerCaseKeys(item))
    )
  }
})

const getClass = (index: number) => {
  return {
    'autocomplete-suggestion': true,
    'autocomplete-selected': currentSelectionIndex.value === index
  }
}

const handleMouseEnter = (index: number) => {
  currentSelectionIndex.value = index
}

const handleKeyword = (event: Event) => {
  const target = event.target as HTMLInputElement
  textValue.value = target.value

  stopKillSuggestions()
  intervalId = window.setTimeout(async () => {
    await getSuggestItem(textValue.value)

    stopKillSuggestions()
  }, 500)

  if (textValue.value == '') {
    emptyValue = true
  } else {
    emptyValue = false
  }
}

const stopKillSuggestions = () => {
  window.clearTimeout(intervalId)
}

const getSuggestItem = async (val: string) => {
  if (val.length > 1) {
    const response = await axios.get(
      `${props.searchUrl}?companyCode=&keyword=${val}&langCode=${props.langCode}&limit=${999}&page=${1}`
    )

    if (response.data.success) {
      filteredItems.splice(0)
      Object.assign(filteredItems, response.data.data)
    }
  }
}

const onFocus = () => {
  isInputFocused.value = true
  //context.emit('onFocus', { input: input, items: filteredItems });
}

const onBlur = () => {
  isInputFocused.value = false
  //context.emit('onBlur', { input: input, items: filteredItems });
}

const onArrowUp = () => {
  if (isListVisible.value && currentSelectionIndex.value > 0) {
    currentSelectionIndex.value--
  }
  scrollSelectionIntoView()
}

const onArrowDown = () => {
  if (isListVisible.value && currentSelectionIndex.value < filteredItems.length - 1) {
    currentSelectionIndex.value++
  }
  scrollSelectionIntoView()
}

const scrollSelectionIntoView = () => {
  setTimeout(() => {
    const list_node: HTMLElement = mikUserList.value
    const active_node: HTMLElement = mikUserListItem.value[currentSelectionIndex.value]

    if (
      !(
        active_node.offsetTop >= list_node.scrollTop &&
        active_node.offsetTop + active_node.offsetHeight < list_node.scrollTop + list_node.offsetHeight
      )
    ) {
      let scroll_to = 0
      if (active_node.offsetTop > list_node.scrollTop) {
        scroll_to = active_node.offsetTop + active_node.offsetHeight - list_node.offsetHeight
      } else if (active_node.offsetTop < list_node.scrollTop) {
        scroll_to = active_node.offsetTop
      }

      list_node.scrollTo(0, scroll_to)
    }
  })
}

const selectCurrentSelection = () => {
  if (currentSelection.value) {
    selectItem(currentSelection.value)
  }
}

const selectCurrentSelectionTab = () => {
  if (props.selectOnTab) {
    selectCurrentSelection()
  } else {
    inputRef.value.blur()
  }
}

const selectItem = (item: OrgModel) => {
  inputRef.value.blur()
  currentSelectionIndex.value = 0
  // 선택 후 초기화
  filteredItems.splice(0)
  textValue.value = ''
  inputRef.value.focus()
  emptyValue = true

  if (selectedItemArr.length < props.maxLengthItem) {
    if (selectedItemArr.filter((selected: OrgModel) => selected.userID == item.userID).length == 0) {
      selectedItemArr.push(item)
      emit('update:modelValue', selectedItemArr)
    } else {
      // 중복
      toast.warning(t('SearchUserDuplicateUsers', [item.userName]))
    }
  } else {
    // 한계 초과
    toast.warning(t('MaxUser'))
  }
}

const escapeRegExp = (text: string) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const boldMatchText = (text: string) => {
  const regexp = new RegExp(`(${escapeRegExp(textValue.value)})`, 'ig')
  return text.replace(regexp, '<strong>$1</strong>')
}

const clearInput = () => {
  textValue.value = ''
}

const getInput = (): HTMLElement => {
  return inputRef.value
}

const focusInput = () => {
  inputRef.value.focus()
  onFocus()
}

const blurInput = () => {
  inputRef.value.blur()
  onBlur()
}

defineExpose({
  clearInput,
  getInput,
  focusInput,
  blurInput
})

const isListVisible = computed(() => {
  return isInputFocused.value && filteredItems.length > 0
})

const currentSelection = computed(() => {
  return isListVisible.value && currentSelectionIndex.value < filteredItems.length
    ? filteredItems[currentSelectionIndex.value]
    : undefined
})

const deleteSelectedItem = (index: number) => {
  selectedItemArr.splice(index, 1)
  emit('update:modelValue', selectedItemArr)
}

const clickOrgchart = () => {
  const orgData = []
  for (const select of selectedItemArr) {
    // @ts-ignore
    orgData.push(upperCaseKeys<OrgModel>(select))
  }

  om_OpenOrgChart({
    appType: 'user',
    oneSelect: props.maxLengthItem == 1,
    data: JSON.stringify(orgData),
    callback: (data: string) => {
      const items = JSON.parse(data)

      if (items != null && items.length > 0) {
        selectedItemArr.splice(0)
        for (const item of items) {
          if (selectedItemArr.length < props.maxLengthItem) {
            // @ts-ignore
            selectedItemArr.push(lowerCaseKeys(item))
          } else {
            // 한계 초과
            toast.warning(t('MaxUser'))
            emit('update:modelValue', selectedItemArr)
            break
          }
        }
        emit('update:modelValue', selectedItemArr)
      }
    }
  })
}

const deleteInput = () => {
  if (emptyValue) {
    deleteSelectedItem(selectedItemArr.length - 1)
  }
}
const emit = defineEmits<{ (e: 'update: ', value: OrgModel[]): void }>()

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue != null) {
      Object.assign(
        selectedItemArr,
        props.modelValue.map((item) => lowerCaseKeys(item))
      )
    }
  }
)
</script>

<style scoped>
.mik-user {
  position: relative;
  border: 1px solid #ddd;
  padding: 4px 5px 0px 5px;
  width: 100%;
  display: inline-block;
  /* height: 47px; */
}

.mik-user > input {
  margin-bottom: 0;
}

.orgchart-btn {
  float: right;
  margin-bottom: 4px;
}

.mik-user .autocomplete-suggestions {
  position: absolute;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
  max-height: 400px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 20;
  min-width: 300px;
}

.mik-user .autocomplete-suggestions.nano {
  overflow: hidden;
}

.mik-user .autocomplete-suggestions div {
  margin: 0;
  padding: 0;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion {
  background-color: white;
  height: 60px;
  box-sizing: border-box;
  border-bottom: 1px solid #d8d8d8;
  text-align: left;
  padding: 2px 0;
  cursor: pointer;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion:first-child {
  padding: 0 0 2px;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion.autocomplete-selected {
  background: #f0f0f0;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion:first-of-type {
  margin-top: 0;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion > .photo-box,
.description-box {
  display: inline-block;
  vertical-align: top;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion > .photo-box img {
  height: 60px;
  width: 60px;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion > .photo-box img[src=''] {
  display: none;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion > .description-box {
  padding: 5px 5px 0 15px;
  margin-top: -2px;
  width: calc(100% - 85px);
  height: 100%;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion > .description-box .name,
.mik-user .autocomplete-suggestions .autocomplete-suggestion > .description-box .email {
  overflow: hidden;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion > .description-box .name {
  font-size: 16px;
  line-height: 16px;
  margin-top: 9px;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion > .description-box .email {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.mik-user .autocomplete-suggestions .autocomplete-suggestion > .description-box .name,
.email {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mik-user .taginput_tag {
  box-sizing: border-box;
  color: black;
  background-color: transparent;
  border: none;
  margin-right: 4px;
  vertical-align: bottom;
  position: relative;
}

.mik-user .taginput_tag.focus .comb-box,
.mik-user .taginput_tag:focus .comb-box {
  outline: #978a7e solid 0.5px;
}

.mik-user .taginput_tag .delete_tag {
  width: 16px;
  height: 16px;
  display: none;
  vertical-align: top;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: transparent;
}

.mik-user .taginput_tag:hover .delete_tag {
  display: inline-block;
}

/* .mik-user .mik_taginput .delete_tag g.svg_delete_btn line {
  stroke: #424242;
}
.mik-user .mik_taginput .delete_tag:hover g.svg_delete_btn line {
  stroke: white;
}
.mik-user .mik_taginput .delete_tag:hover g.svg_delete_btn rect {
  fill: #ff5722;
  stroke: #978A7E;
} */
.mik-user .taginput_tag.focus .comb-box,
.mik-user .taginput_tag:focus .comb-box {
  background-color: #e9e9e9;
}

.mik-user .taginput_tag.selected .comb-box {
  color: white;
  background-color: #ff5722;
}

.mik-user .taginput_tag .comb-box {
  box-sizing: border-box;
  display: table-row;
  background-color: #f4f4f4;
  position: relative;
}

.mik-user .taginput_tag .comb-box .photo {
  box-sizing: border-box;
  height: 35px;
  width: 34px;
  display: table-cell;
}

.mik-user .taginput_tag .comb-box .photo[src=''] {
  display: none;
}

.mik-user .taginput_tag .comb-box .photo[src=''] ~ .name {
  height: 35px;
}

.mik-user .taginput_tag .comb-box .name {
  box-sizing: border-box;
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  padding: 0 10px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mik-user .mik-user-input {
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: top;
  border: 0px;
  height: 36px;
}

.mik-user .mik-user-input .taginput_edit {
  width: 100%;
  box-sizing: border-box;
}

.orgchart {
  margin-top: 12px;
  display: inline-block;
  margin-left: 5px;
  vertical-align: top;
}
</style>
