<template>
  <q-input
    ref="inputRef"
    v-model="formattedValue"
    dense
    :label="inputLabel"
    :style="`width: ${width}px;`"
    input-class="text-right"
    clearable />
</template>

<script setup lang="ts">
import { CurrencyDisplay, useCurrencyInput } from 'vue-currency-input'

export interface Props {
  // v-model
  modelValue?: number | string | null
  // 통화단위
  currency?: string
  // 입력란위쪽 작게 라벨표시
  inputLabel?: string
  // 지역
  locale?: string
  // 최대
  maxValue?: number
  // 최소
  minValue?: number
  // 필드 길이
  width?: number
  // 소수점
  precision?: string
}

const props = withDefaults(defineProps<Props>(), {
  // v-model
  modelValue: null,
  // 통화단위
  currency: 'KRW',
  // 입력란위쪽 작게 라벨표시
  inputLabel: '',
  // 지역
  locale: 'ko-KR',
  // 최대
  maxValue: undefined,
  // 최소
  minValue: undefined,
  // 필드길이
  width: 200,
  // 소수점 최대
  precision: '0'
})

const { inputRef, formattedValue, setValue } = useCurrencyInput({
  currency: props.currency,
  locale: props.locale,
  // 기호만 나오도록.
  currencyDisplay: props.currency == null ? CurrencyDisplay.hidden : CurrencyDisplay.narrowSymbol,
  // 포커싱 후 Symbol이 표시되도록
  hideCurrencySymbolOnFocus: false,
  valueRange: {
    max: props.maxValue,
    min: props.minValue
  },
  precision: {
    min: 0,
    max: Number(props.precision)
  }
})

watch(
  () => props.modelValue,
  (value: string | number | null) => {
    if (typeof value === 'string') {
      if (value == '') {
        setValue(null)
      } else {
        setValue(Number(value))
      }
    } else {
      setValue(value)
    }
  }
)

const focus = () => {
  inputRef.value.focus()
}
</script>
