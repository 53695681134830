<template>
  <iframe
    ref="frame"
    :src="`${frameUrl}`"
    scrolling="no"
    class="pdf-viewer"
    width="100%"
    height="1000px"
    @load="loadIframe"></iframe>
</template>

<script setup lang="ts">
import axios from 'axios'
import { Cookies } from 'quasar'

export interface Props {
  url: string
  isCommonPopup?: boolean
  useScramble?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  url: '',
  isCommonPopup: true,
  useScramble: true
})

const langCode = Cookies.get('GWP_LANGUAGE_CODE') ?? 'ko'
const src = ref<Uint8Array>()
const frameUrl = ref(`${import.meta.env.V_RESOURCE_BASE_PATH}/lib/pdfjs/web/viewer.html?t=20240112#locale=${langCode}`)

//console.log(frameUrl.value)

const frame = ref<HTMLIFrameElement>()

/** 역스크램블된 FileStream 가져오기 */
const getFileStreamAsync = (data: any) => {
  const input = new Uint8Array(data)
  if (props.useScramble) {
    const blockSize = 4096,
      fileSize = data.byteLength
    const output = new Uint8Array(input.byteLength)
    let pos = 0
    while (pos < fileSize) {
      const buffer = input.slice(pos, pos + blockSize).reverse()
      output.set(buffer, pos)
      pos += buffer.byteLength
    }
    return output
  }
  return input
}

/** PDF Preview File 호출 Api */
const getPdfPreview = () => {
  axios.get(props.url, { responseType: 'arraybuffer' }).then((result) => {
    src.value = getFileStreamAsync(result.data)
  })
}

/** iframe 로드 시 동작 함수 */
const loadIframe = () => {
  // html overflow hidden 처리
  const html = document.getElementsByTagName('html')[0]
  html.setAttribute('style', 'overflow-y:hidden;')

  loadPdf()
}

const loadPdf = () => {
  if (frame.value && frame.value.contentWindow) {
    // @ts-ignore
    const viewer = frame.value.contentWindow.PDFViewerApplication
    if (src.value) {
      setIframeSize()
      viewer.open(src.value)
    } else {
      setTimeout(() => {
        loadPdf()
      }, 500)
    }
  }
}

/** iframe 사이즈 세팅 동작 시점
 *  1. iframe 최초 로드 시
 *  2. window resize 시
 * */
const setIframeSize = () => {
  if (frame.value) {
    if (props.isCommonPopup) {
      const height =
        (frame.value?.parentElement?.parentElement?.parentElement?.clientHeight ?? window.innerHeight) - 102
      frame.value.style.height = `${height}px`
    } else {
      frame.value.style.height = `${window.innerHeight}px`
      frame.value.style.width = `${window.innerWidth}px`
    }
  }
}

onBeforeMount(() => {
  // mount 되기 전 pdf file 호출
  getPdfPreview()
})
onMounted(() => {
  // mount 된 후 iframe resize event 추가
  window.addEventListener('resize', () => setIframeSize())
})
onUnmounted(() => {
  // mount 해제 후 iframe resize event 제거
  window.removeEventListener('resize', () => setIframeSize())

  const html = document.getElementsByTagName('html')[0]
  html.setAttribute('style', 'overflow-y:visible;')
})
</script>
